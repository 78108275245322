.loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  video {
    width: 150%;
  }
}
