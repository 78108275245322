.main-landing {
  background-color: white;
  height: 100%;

  .title {
    font-family: "Playfair Display", serif;
  }

  .section-one {
    width: 100%;
    background-image: url("../../assets/landing/background.png");
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 2000px rgba(97, 69, 45, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
      max-width: 1366px;
      padding-top: 100px;
      padding-bottom: 100px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        margin: 0;
        font-size: 70px;
        color: #eee1cf;
      }

      .description {
        font-family: "Monserrat";
        font-size: 30px;
        color: #eee1cf;
        max-width: 800px;
        margin-bottom: 120px;
      }

      .logo {
        width: 245px;
      }
    }
  }

  .section-two {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .branch {
      position: absolute;
      width: 200px;
      height: 200px;
      left: 0px;
      top: 0px;
    }

    .content {
      max-width: 1366px;
      padding-top: 150px;
      padding-bottom: 200px;
      display: flex;
      flex-direction: row;
      padding-left: 50px;
      padding-right: 50px;

      .profile-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 50px;

        .profile {
          width: 400px;
          margin-bottom: 50px;
        }

        .logo {
          width: 100px;
        }
      }

      .history-content {
        .title {
          font-size: 45px;
          color: #703f31;
          text-transform: uppercase;
        }

        .item {
          font-size: 30px;
          font-family: "Monserrat";
          /*max-width: 500px;*/
          display: block;
          color: #997362;
        }
      }
    }
  }
}
